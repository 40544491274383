<template>
  <transition name="modal">
    <div class="modal-mask" @click.self="$emit('close')">
      <div class="modal-container">
        <div class="modal-header">
          <div class="head">
            <div class="start">
              <div class="breadcrumb">
                <p>feed - {{ currentTeamName }}</p>
                <span class="slash">/</span>
              </div>
              <div class="cancel clickable" @click="$emit('close')">
                <i class="el-icon-close ver" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="feed d-flex"
          v-for="i in completedInterchanges"
          :key="i._id"
        >
          <div class="hard">
            {{ formatTime(i.min) }} :
            {{ formatTime(i.sec) }}
          </div>
          <div class="d-flex-column flex-1 feed-body">
            <div>
              <b> {{ i.name }} - {{ getTeamName(i.teamId) }} </b>
            </div>
            <div v-if="i.meta.on">
              <b> On: </b> {{ i.meta.on.firstName }}
              {{ i.meta.on.lastName }} ({{ i.meta.on.shirtNum }})
            </div>
            <div v-if="i.meta.off">
              <b> Off: </b> {{ i.meta.off.firstName }}
              {{ i.meta.off.lastName }} ({{ i.meta.off.shirtNum }})
            </div>
          </div>
          <div class="d-flex clickable" @click="deleteInterchange(i)">
            <i class="el-icon-remove ver" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'FeedSideModal',
    props: ['selectedTeamId'],
    computed: {
      completedInterchanges: {
        get() {
          return this.selectedTeamId
            ? this.$store.state.match.completedInterchanges.filter(
                (t) => t.teamId === this.selectedTeamId
              )
            : this.$store.state.match.completedInterchanges;
        },
        set(newValue) {
          const isHomeTeam =
            this.currentMatch.homeTeam._id === this.selectedTeamId;
          this.$store.commit('match/UPDATE_INTERCHANGES_FROM_FEED', {
            isHomeTeam,
            interchanges: newValue,
          });
        },
      },
      currentTeamName() {
        return this.getTeamName(this.selectedTeamId);
      },
      currentMatch() {
        return this.$store.state.match.currentMatch;
      },
    },
    methods: {
      getTeamName(_id) {
        if (this.currentMatch.homeTeam._id === _id) {
          return this.currentMatch.homeTeam.name;
        } else {
          return this.currentMatch.awayTeam.name;
        }
      },
      deleteInterchange(interchange) {
        const h = this.$createElement;
        this.$msgbox({
          title: 'Confirm',
          message: h('p', null, [
            h(
              'span',
              null,
              `Are you sure you want to delete the following interchange:`
            ),
            h('br'),
            h(
              'strong',
              null,
              `${interchange.name} - ${this.formatTime(
                interchange.min
              )}:${this.formatTime(interchange.sec)}`
            ),
            h('br'),
            h('span', null, `${this.getTeamName(interchange.teamId)}`),
          ]),
          showCancelButton: true,
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            this.completedInterchanges = this.completedInterchanges.filter(
              (i) => i._id !== interchange._id
            );
          })
          .catch(() => {
            return;
          });
      },
      formatTime(int) {
        return int < 10 ? `0${int}` : int;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-container {
    transform: unset;
    left: 0;
    top: 0;
    height: 100%;
    max-width: 500px;
  }
  .vertical-center {
    margin-top: auto;
    margin-bottom: auto;
  }
  .big-input {
    width: 100px;
    height: 40px;
    font-size: 1.5em;
    border: 2px solid black;
    margin-left: 10px;
    text-align: center;
    font-weight: bold;
    color: black;
  }
  .cap {
    font-size: 1.2em;
    color: grey;
  }
  button {
    width: 130px;
  }

  .line {
    display: flex;
    justify-content: space-between;
  }
  .name {
    font-size: 1.5em;
    display: flex;
  }
  .head {
    height: 50px;
    display: flex;
    margin-bottom: 10px;

    > * {
      margin-top: auto;
      margin-bottom: auto;
    }

    .start {
      flex-grow: 1;
      text-align: left;
    }
  }

  .loose-column-button-wrapper {
    > button,
    div {
      margin-top: 15px;
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      font-size: 0.66rem;
    }
  }
  .align-text-center {
    margin-left: auto;
    margin-right: auto;
  }

  .small-text-wrap {
    width: 100%;
    text-align: left;
    .small-text {
      text-transform: uppercase;
      font-size: 0.7em;
    }
  }
  .cancel {
    position: absolute;
    right: 20px;
    top: 30px;
  }

  .feed {
    border-top: 1px solid #eeeeee;
    &:last-of-type {
      border-bottom: 1px solid #eeeeee;
    }
    padding-top: 5px;
    .feed-body {
      margin-left: 25px;
      text-align: left;
    }
  }
  .ver {
    margin-top: auto;
    margin-bottom: auto;
  }

  .hard {
    min-width: 50px;
    text-align: left;
    font-weight: bold;
  }
</style>
