<template>
  <component :is="Default">
    <!--  feed side modal  -->
    <FeedSideModal
      v-if="showFeedModal"
      @close="showFeedModal = false"
      :selectedTeamId="selectedTeam._id"
    />
    <!--  modals  -->
    <InterchangeModal
      v-if="showInterchangeModal"
      @submit="submitInterchange"
      @close="cancelInterchange"
      :selectedPlayers="
        selectedPlayers.map((s) =>
          currentTeamList.player.teamList.find((p) => p._id === s),
        )
      "
    />
    <OverrideModal
      v-if="showOverrideModal"
      @submit="submitOverride"
      @close="cancelInterchange"
      :selectedPlayers="
        selectedPlayers.map((s) =>
          currentTeamList.player.teamList.find((p) => p._id === s),
        )
      "
    />
    <!--    header -->
    <template slot="buttons">
      <!-- Start Online Status Bar -->
      <div class="status d-flex">
        <div class="online d-flex" :class="{ off: !networkOnline }">
          <div class="auto-white">
            <i class="el-icon-sort"></i>
          </div>
        </div>
        <div
          @click="resendApiRequests"
          class="saved d-flex"
          :class="{
            off: apiRequestFailed.gameState || apiRequestFailed.interchange,
          }"
        >
          <div class="auto-white">
            {{
              apiRequestFailed.gameState || apiRequestFailed.interchange
                ? "Retry Save"
                : "Saved"
            }}
          </div>
        </div>
      </div>
      <!-- End Online Status Bar -->
      <div class="buttons">
        <button class="loose thin" @click="$router.push('/landing')">
          Dashboard
        </button>
        <button class="loose transparent thin" @click="$logout">Log Out</button>
      </div>
    </template>
    <!--    footer -->
    <template slot="foot">
      <div class="footer-wrap">
        <div class="flex-1 buttons">
          <button
            class="b1 transparent loose"
            @click="$router.push({ name: 'matches' })"
          >
            Matches
          </button>
        </div>
        <div class="buttons align-right">
          <div v-if="!(confirmHomeStatus && confirmAwayStatus)" class="d-flex">
            <button
              @click="submitTeamList"
              :disabled="disableSubmitButton"
              class="b1 loose"
            >
              Confirm List
            </button>
          </div>
          <div v-else class="nowrap">
            <button
              v-for="(gameState, i) in eliteGameStates"
              :key="i"
              class="b1 loose"
              @click="setGameState(gameState)"
              :class="{
                transparent: !(
                  latestGameState && latestGameState.type === gameState.type
                ),
              }"
            >
              {{ gameState.name }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <!--    body -->
    <Match
      v-if="currentMatch && currentMatch.venue"
      :place="currentMatch.venue.name"
      :team1="currentMatch.homeTeam.name"
      :team2="currentMatch.awayTeam.name"
      :club1="currentMatch.homeTeam.orgtree.club"
      :club2="currentMatch.awayTeam.orgtree.club"
      :score1="currentMatch.scores ? currentMatch.scores.homeTeam : undefined"
      :score2="currentMatch.scores ? currentMatch.scores.awayTeam : undefined"
      :time="matchTime(currentMatch.dateTime)"
      :title="
        `Round ${currentMatch.round.number} ${matchDate(currentMatch.dateTime)}`
      "
      class="rel"
    >
      <div class="float-button-left">
        <button class="transparent" @click="feedOpen">
          <span class="bt">feed</span>
        </button>
      </div>
    </Match>
    <div class="columns">
      <!-- Columns are reactive and fill 100% width in portrait/mobile mode -->
      <div class="w-3 column">
        <!-- Column 1 - Team selection -->
        <div class="mini-header">
          team selection
        </div>
        <form
          @submit="submitTeamListOverride(currentMatch.homeTeam._id)"
          id="overrideHome"
          style="padding:10px 0px;display:flex;flex-wrap:wrap;justify-content:end;align-items:flex-start;"
        >
          <div
            style="flex:1;min-width: 66%"
            v-if="currentMatch"
            class="card clickable"
            :class="{
              selected: selectedTeam._id === currentMatch.homeTeam._id,
            }"
            @click="selectedTeam = currentMatch.homeTeam"
          >
            <div>
              {{ interchangeNumber.home }} <br />
              {{ currentMatch.homeTeam.name }}
            </div>
          </div>
          <div
            style="margin-top:8px;"
            class="override"
            v-if="homeTeamList && homeTeamList.override"
          >
            REL
          </div>
          <label
            style="margin-top:8px;"
            class="btn"
            v-if="homeTeamList && !homeTeamList.override"
            @click="overrideCheck('Home')"
          >
            OVR
          </label>
        </form>
        <form
          style="padding:10px 0px;display:flex;flex-wrap:wrap;justify-content:flex-end;align-items:flex-start;"
          @submit="submitTeamListOverride(currentMatch.awayTeam._id)"
          id="overrideAway"
        >
          <div
            v-if="currentMatch"
            class="card clickable"
            style="flex:1;min-width: 66%"
            :class="{
              selected: selectedTeam._id === currentMatch.awayTeam._id,
            }"
            @click="selectedTeam = currentMatch.awayTeam"
          >
            <div>
              {{ interchangeNumber.away }} <br />
              {{ currentMatch.awayTeam.name }}
            </div>
          </div>
          <div
            style="margin-top:8px;"
            class="override"
            v-if="awayTeamList && awayTeamList.override"
          >
            REL
          </div>
          <label
            style="margin-top:8px;"
            class="btn"
            v-if="awayTeamList && !awayTeamList.override"
            @click="overrideCheck('Away')"
          >
            OVR
          </label>
        </form>
      </div>
      <div class="w-0 column">
        <!-- Column 2 - Canvas with player positions -->
        <div class="canvas">
          <div
            v-for="position in startingPositions"
            :key="position.number"
            class="player"
            :style="getPosition(position.number)"
          >
            <div
              class="number-circle green clickable"
              :class="{
                selected:
                  players[position.number] &&
                  selectedPlayers.length > 0 &&
                  selectedPlayers.includes(players[position.number]._id),
                available:
                  (players[position.number] &&
                    playersInterchange.filter(
                      (i) => selectedPlayers[0] === i._id,
                    ).length !== 0) ||
                  (players[position.number] &&
                    selectedPlayers.length > 0 &&
                    Object.values(replacementPlayer).find(
                      (n) => n._id === selectedPlayers[0],
                    )),
              }"
              @click="
                selectPlayer(
                  players[position.number]
                    ? players[position.number]._id
                    : undefined,
                )
              "
            >
              <b>{{
                players[position.number] !== undefined
                  ? players[position.number].shirtNum
                  : ""
              }}</b>
            </div>
            <div class="player-name-wrap">
              <div class="player-name">
                {{
                  players[position.number] !== undefined
                    ? `${players[position.number].firstName[0]}. ${
                        players[position.number].lastName
                      } ${hadHIA(players[position.number])}`
                    : ""
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-3 column">
        <!-- Column 3 - Lists of off-field players -->
        <div class="mini-header">
          Interchange Bench
        </div>
        <div
          v-for="i in playersInterchange"
          :key="i._id"
          class="mt-10 card name-tag d-flex clickable"
          :class="{
            selected:
              selectedPlayers.length > 0 && selectedPlayers.includes(i._id),
            available:
              selectedPlayers.length > 0 &&
              Object.values(players).find((n) => n._id === selectedPlayers[0]),
          }"
          @click="selectPlayer(i._id)"
        >
          <div class="number-circle">
            <b>{{ i.shirtNum }}</b>
          </div>
          <div class="flex-1 profile-name">
            {{ i.firstName }} <b>{{ i.lastName }}</b>
          </div>
          <div
            v-if="
              i.hia ||
                i.foulPlayHIA ||
                i.foulPlay ||
                i.hiaCleared ||
                i.hadHIA ||
                i.activatedReplacementPlayer
            "
            class="red"
          >
            {{ hiaStatus(i) }}
          </div>
        </div>
        <br />
        <div class="mini-header">
          18th Player Replacement
        </div>
        <div
          v-for="i in replacementPlayer"
          :key="i._id"
          class="mt-10 card name-tag d-flex clickable"
          :class="{
            selected:
              selectedPlayers.length > 0 && selectedPlayers.includes(i._id),
            available:
              selectedPlayers.length > 0 &&
              Object.values(players).find((n) => n._id === selectedPlayers[0]),
          }"
          @click="selectPlayer(i._id)"
        >
          <div class="number-circle">
            <b>{{ i.shirtNum }}</b>
          </div>
          <div class="flex-1 profile-name">
            {{ i.firstName }} <b>{{ i.lastName }}</b>
          </div>
          <div
            v-if="
              i.hia || i.foulPlayHIA || i.foulPlay || i.hiaCleared || i.hadHIA
            "
            class="red"
          >
            {{ hiaStatus(i) }}
          </div>
        </div>
        <br />
        <div v-if="!(confirmHomeStatus && confirmAwayStatus)">
          <div class="mini-header">
            Team Staff
          </div>
          <div
            class="mt-10 card name-tag d-flex"
            v-for="i in staff"
            :key="i._id"
          >
            <div class="flex-1 profile-name">
              {{ i.firstName }} <b>{{ i.lastName }}</b>
              <br />
              <span>{{ getStaffRoleName(i.role) }}</span>
            </div>
          </div>
        </div>
        <div class="mini-header">
          Player Off Field
        </div>
        <div
          class="mt-10 card name-tag d-flex highlight"
          v-if="
            selectedPlayers.length > 0 &&
              playersInterchange.find((n) => n._id === selectedPlayers[0]) &&
              (playersInterchange.find((n) => n._id === selectedPlayers[0])
                .hia ||
                playersInterchange.find((n) => n._id === selectedPlayers[0])
                  .foulPlayHIA ||
                playersInterchange.find((n) => n._id === selectedPlayers[0])
                  .foulPlay ||
                playersInterchange.find(
                  (n) => n._id === selectedPlayers[0].sinbin,
                ))
          "
          @click="showInterchangeModal = true"
        >
          <div class="flex-1 profile-name">
            Move Here
          </div>
        </div>
        <div
          class="mt-10 card name-tag d-flex highlight"
          v-if="
            selectedPlayers.length > 0 &&
              Object.values(players).find((n) => n._id === selectedPlayers[0])
          "
          @click="showInterchangeModal = true"
        >
          <div class="flex-1 profile-name">
            Move Here
          </div>
        </div>
        <div
          class="mt-10 card name-tag d-flex clickable"
          v-for="i in playersOffField"
          :key="`offield_${i._id}`"
          :class="{
            selected:
              selectedPlayers.length > 0 && selectedPlayers.includes(i._id),
            available:
              selectedPlayers.length > 0 &&
              Object.values(replacementPlayer).find(
                (n) => n._id === selectedPlayers[0],
              ),
          }"
          @click="selectPlayer(i._id)"
        >
          <div class="number-circle">
            <b>{{ i.shirtNum }}</b>
          </div>
          <div class="flex-1 profile-name">
            {{ i.firstName }} <b>{{ i.lastName }}</b>
          </div>
          <div class="red">
            {{ offFieldStatus(i) }}
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </component>
</template>

<script>
import generateHash from "random-hash";
import Default from "@/layouts/default";
import Match from "@/components/MatchCard";
import moment from "moment";
import InterchangeModal from "@/components/modals/InterchangeModal";
import OverrideModal from "@/components/modals/OverrideModal";
import FeedSideModal from "@/components/modals/FeedSideModal";
import SubmitModal from "@/components/modals/SubmitModal";
import {
  startingPositions,
  eliteGameStates,
  nonPlayerRoles,
} from "../utils/constants";

/* Canvas postion of players on canvas.
Keys represent the standard shirt numbers (player.position.number not player.position.order)
for positions under NRL rules*/
const position = {
  8: [0, 0],
  9: [50, 0],
  10: [100, 0],
  11: [25, 15],
  12: [75, 15],
  13: [50, 30],
  7: [20, 45],
  6: [50, 55],
  3: [25, 75],
  4: [75, 75],
  2: [0, 95],
  1: [50, 95],
  5: [100, 95],
};

export default {
  name: "matchevents",
  components: {
    FeedSideModal,
    Match,
    InterchangeModal,
    OverrideModal,
    SubmitModal,
  },
  data() {
    return {
      Default,
      selectedTeam: "",
      confirmHomeStatus: false,
      confirmAwayStatus: false,
      completedGameStates: [],
      showInterchangeModal: false,
      showOverrideModal: false,
      showSubmitListModal: false,
      showFeedModal: false,
      startingPositions,
      homeTeamList: undefined,
      awayTeamList: undefined,
      selectedPlayers: [],
      eliteGameStates,
      networkOnline: true,
      apiRequestFailed: { gameState: false, interchange: false },
      currentMatch: undefined,
      blockSendInterchange: false,
      blockRedirectConfirm: false,
    };
  },
  computed: {
    completedInterchanges: {
      get() {
        return this.$store.state.match.completedInterchanges;
      },
      set(newValue) {
        this.$store.commit("match/UPDATE_INTERCHANGES", newValue);
      },
    },
    isHomeTeam() {
      return this.selectedTeam && this.currentMatch
        ? this.selectedTeam._id === this.currentMatch.homeTeam._id
        : undefined;
    },
    currentTeamList() {
      if (this.currentMatch && this.isHomeTeam === true) {
        return this.homeTeamList;
      }
      if (this.currentMatch && this.isHomeTeam === false) {
        return this.awayTeamList;
      }
      return undefined;
    },
    players() {
      if (this.currentTeamList) {
        return this.currentTeamList.player.teamList
          .filter(
            (p) =>
              !p.hia &&
              !p.sinBin &&
              !p.sendOff &&
              p.position.number <= 13 &&
              !p.playerOffField &&
              !p.ruledOut,
          )
          .reduce((acc, p) => {
            acc[p.position.number] = p;
            return acc;
          }, {});
      } else {
        return {};
      }
    },
    playersInterchange() {
      if (this.currentTeamList) {
        return this.currentTeamList.player.teamList.filter(
          (p) =>
            p.position.number > 13 &&
            !p.hiaNotCleared &&
            p.position.abbreviation !== "18M" &&
            !p.ruledOut,
        );
      } else {
        return [];
      }
    },
    replacementPlayer() {
      if (this.currentTeamList) {
        return this.currentTeamList.player.teamList.filter(
          (p) =>
            p.position.number > 17 &&
            !p.hiaNotCleared &&
            p.position.abbreviation === "18M",
        );
      } else {
        return [];
      }
    },
    staff() {
      if (this.currentTeamList) {
        return this.currentTeamList.nonPlayer.teamList;
      } else {
        return [];
      }
    },

    playersOffField() {
      if (this.currentTeamList) {
        return this.currentTeamList.player.teamList.filter(
          (p) =>
            p.sinBin ||
            p.sendOff ||
            p.playerOffField ||
            p.hiaNotCleared ||
            p.ruledOut,
        );
      } else {
        return [];
      }
    },
    disableSubmitButton() {
      return (
        (this.confirmHomeStatus && this.isHomeTeam) ||
        (this.confirmAwayStatus && !this.isHomeTeam)
      );
    },
    gameActive() {
      return this.confirmHomeStatus && this.confirmAwayStatus;
    },
    latestGameState() {
      return (
        this.completedGameStates.length > 0 &&
        this.completedGameStates.reduce((acc, g) =>
          g.timestamp > acc.timestamp ? g : acc,
        )
      );
    },
    period() {
      if (!this.latestGameState) {
        return undefined;
      } else if (this.latestGameState.type === 3) {
        return 1;
      } else if (this.latestGameState.type === 5) {
        return 2;
      } else if (this.latestGameState.type === 6) {
        return 3;
      } else {
        return undefined;
      }
    },
    periodLength() {
      return this.currentMatch.competition.periodLength
        ? this.currentMatch.competition.periodLength
        : 40;
    },
    interchangeNumber() {
      return {
        home: this.completedInterchanges.reduce((acc, m) => {
          if (m.interchangeCounts == true && m.isHomeTeam === true) acc += 1;
          return acc;
        }, 0),
        away: this.completedInterchanges.reduce((acc, m) => {
          if (m.interchangeCounts == true && m.isHomeTeam === false) acc += 1;
          return acc;
        }, 0),
      };
    },
  },
  methods: {
    toggleNetwork() {
      this.networkOnline = !this.networkOnline;
    },
    feedOpen() {
      this.showFeedModal = true;
    },
    getStaffRoleName(role) {
      return nonPlayerRoles.find((r) => r.type === role) &&
        nonPlayerRoles.find((r) => r.type === role).name
        ? nonPlayerRoles.find((r) => r.type === role).name
        : "Role Not Applicable";
    },
    hadHIA(player) {
      if (
        player.hiaClearedWithin15 ||
        player.hiaClearedOutside15 ||
        player.hiaCleared ||
        player.hadHIA
      )
        return "⭐";
      else if (player.activatedReplacementPlayer) return "🔄";
      else return "";
    },
    hiaStatus(player) {
      if (player.hia) return "HIA";
      if (player.foulPlay) return "FP";
      if (player.foulPlayHIA) return "FPHIA";
      if (player.hadHIA) return "⭐";
      if (player.hiaCleared) return "⭐";
      if (player.hiaClearedOutside15) return "⭐";
      if (player.hiaClearedWithin15) return "⭐";
      if (player.activatedReplacementPlayer) return "🔄";
    },
    offFieldStatus(player) {
      if (player.sinBin) return "SIN";
      if (player.sendOff) return "OUT";
      if (player.playerOffField) return "OFF";
      if (player.hiaNotCleared) return "HIA";
      if (player.ruledOut) return "FP";
      return "";
    },
    selectPlayer(_id) {
      if (!this.gameActive) return;
      if (!_id) return;
      const player1 = this.currentTeamList.player.teamList.find(
        (n) => n._id === this.selectedPlayers[0],
      );
      const player2 = this.currentTeamList.player.teamList.find(
        (n) => n._id === _id,
      );

      if (this.selectedPlayers[0]) {
        /* Only 2 players can be selected if one is in a valid field position
        and the other a valid interchange position */
        if (
          !this.currentTeamList.player.teamList
            .map((n) => n._id)
            .includes(this.selectedPlayers[0])
        ) {
          this.selectedPlayers = [_id];
          return;
        }

        if (
          player1._id !== player2._id &&
          player1.position.abbreviation !== "18M" &&
          !(
            (player1.position.number <= 13 &&
              !player1.sinBin &&
              !player1.sendOff &&
              player2.position.number > 13) ||
            (player1.position.number > 13 &&
              player2.position.number <= 13 &&
              !player2.sinBin &&
              !player2.sendOff)
          )
        )
          return;
        if (
          player1._id !== player2._id &&
          player1.position.abbreviation === "18M" &&
          player2.position.number > 13 &&
          !player2.ruledOut
        )
          return;
      }

      if (this.selectedPlayers.includes(_id)) {
        // Unselect player
        this.selectedPlayers = this.selectedPlayers.filter((s) => s !== _id);
      } else {
        // Add player id to selected players
        this.selectedPlayers = this.selectedPlayers.concat([_id]);
      }
      if (this.selectedPlayers.length === 2) {
        // Show interchange if two players are selected
        this.showInterchangeModal = true;
      } else if (
        this.selectedPlayers.length === 1 &&
        (player2.sinBin ||
          player2.sendOff ||
          player2.playerOffField ||
          player2.hiaNotCleared ||
          player2.ruledOut)
      ) {
        /* Show interchange automatically with one selected player
          if they are in HIA Out or Off Field as can only move in one direction */
        this.showInterchangeModal = true;
      }
    },
    overrideCheck(teamType, teamId) {
      // teamType is 'home' || 'away' helps identify which form to submit
      this.$notify({
        type: "warning",
        title: "Warning",
        duration: 0,
        dangerouslyUseHTMLString: true,
        message: `<p>Are you sure you want to allow this teamlist to be overriden?</p><br> <button form="override${teamType}" class="transparent" type="submit" value="${teamId}">yes</button>`,
      });
    },

    async submitTeamListOverride(teamId) {
      try {
        const matchId = this.currentMatch._id;
        if (!matchId || !teamId) return;
        await this.$http.get(
          `nrl/api/v1/elite/teamlists-override/match/${matchId}/team/${teamId}`,
        );
      } catch (e) {
        const message = e.message
          ? e.message
          : "Team List could not be released";
        this.$notify({
          title: "Fail",
          message,
        });
      }
    },
    submitTeamList() {
      this.$confirm(
        `Are you sure you want to confirm the teamlist for ${this.selectedTeam.name} as Final?`,
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
        },
      )
        .then(() => {
          // create safe player list

          if (!this.currentTeamList.player)
            return this.$notify.error({
              message: "No players available please refresh screen",
            });
          if (!this.currentTeamList.player.teamList)
            return this.$notify.error({
              message: "No players available please refresh screen",
            });
          const safePlayers = [];

          for (const p of this.currentTeamList.player.teamList) {
            if (p._id && p.firstName && p.lastName)
              safePlayers.push({
                _id: p._id,
                shirtNum: p.shirtNum,
                firstName: p.firstName,
                middleName: p.middleName || "",
                lastName: p.lastName,
                preferredName:
                  p.preferredName || `${p.firstName} ${p.lastName}`,
                isPrivate: p.isPrivate || false,
                isCaptain: p.isCaptain,
                position: p.position,
              });
          }
          const safeNon = [];
          for (const n of this.currentTeamList.nonPlayer.teamList) {
            if (n && n._id && n.firstName && n.lastName)
              safeNon.push({
                _id: n._id,
                firstName: n.firstName,
                middleName: n.middleName || "",
                lastName: n.lastName,
                preferredName:
                  n.preferredName || `${n.firstName} ${n.lastName}`,
                isPrivate: n.isPrivate || false,
                role: n.role,
              });
          }
          this.$store.commit("LOADING", true);
          const data = {
            matchId: this.currentMatch._id,
            teamId: this.selectedTeam._id,
            status: "final",
            players: safePlayers,
            nonPlayers: safeNon,
          };
          if (
            this.selectedTeam &&
            this.currentMatch.homeTeam &&
            this.selectedTeam._id === this.currentMatch.homeTeam._id
          ) {
            data.override = this.homeTeamList.override;
          }
          if (
            this.selectedTeam &&
            this.currentMatch.awayTeam &&
            this.selectedTeam._id === this.currentMatch.awayTeam._id
          ) {
            data.override = this.awayTeamList.override;
          }
          this.$http
            .post("/nrl/api/v1/elite/teamlists", data)
            .then(() => {
              this.$http
                .get(
                  `/nrl/api/v1/elite/teamlists/match/${this.currentMatch._id}/team/${this.selectedTeam._id}`,
                )
                .then((response) => {
                  if (response) {
                    if (this.isHomeTeam) {
                      this.confirmHomeStatus = true;
                      this.homeTeamList = response.data.data;
                      this.selectedTeam = this.currentMatch.awayTeam;
                    } else {
                      this.confirmAwayStatus = true;
                      this.awayTeamList = response.data.data;
                      this.selectedTeam = this.currentMatch.homeTeam;
                    }
                    this.$notify({
                      title: "Success",
                      message: "Team List Submitted",
                    });
                  }
                  this.$store.commit("LOADING", false);
                })
                .catch((e) => {
                  this.$notify.error({
                    title: "Error",
                    message:
                      e.response && e.response.data && e.response.data.message
                        ? e.response.data.message
                        : "There has been an error",
                  });
                  this.$store.commit("LOADING", false);
                });
            })
            .catch((e) => {
              this.$notify.error({
                title: "Error",
                message:
                  e.response && e.response.data && e.response.data.message
                    ? e.response.data.message
                    : "There has been an error",
              });
              this.$store.commit("LOADING", false);
            });
        })
        .catch((e) => {
          console.log(e);
          this.$notify.error({
            title: "Error",
            message: e.message
              ? e.message
              : "There has been an error. Check with your administrator if problem persists.",
          });
          return;
        });
    },
    getPosition(i) {
      /* Calculates position of field players on canvas.
      Height and width are proportionate to canvas size but
      proportions unknown and set by Exis before he left.*/
      const width = 270;
      const height = 300;

      const x = (position[i][0] / 100) * width;
      const y = (position[i][1] / 100) * height;

      return {
        left: `${x}px`,
        top: `${y}px`,
      };
    },
    matchTime(dateTime) {
      return moment(dateTime).format("h:mm a");
    },
    matchDate(dateTime) {
      return moment(dateTime).format("dddd Do MMMM");
    },
    setGameState(gameState) {
      if(gameState.type === 0) {
        this.$confirm(
          `Are you sure you want to reset the game to ${gameState.name}?`,
          "Confirm",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
          },
        )
          .then(() => {
            this.completedGameStates = this.completedGameStates.concat([
              {
                _id: generateHash({ length: 17 }),
                type: gameState.type,
                name: gameState.name,
                timestamp: moment().valueOf(),
              },
            ]);
            console.log(this.completedGameStates)
            this.sendGameState();
          })
          .catch(() => {
            return;
          });
      }
      else if (gameState.type !== 8) {
        this.$confirm(
          `Are you sure you want to ${
            gameState.type === 4 ? "move to" : "start"
          } ${gameState.name}?`,
          "Confirm",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
          },
        )
          .then(() => {
            this.completedGameStates = this.completedGameStates.concat([
              {
                _id: generateHash({ length: 17 }),
                type: gameState.type,
                name: gameState.name,
                timestamp: moment().valueOf(),
              },
            ]);
            this.sendGameState();
          })
          .catch(() => {
            return;
          });
      } else {
        this.$confirm("Are you sure you want to end the game?", "Confirm", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
        })
          .then(() => {
            this.completedGameStates = this.completedGameStates.concat([
              {
                _id: generateHash({ length: 17 }),
                type: gameState.type,
                name: gameState.name,
                timestamp: moment().valueOf(),
              },
            ]);
            this.sendInterchange();
            this.sendGameState();
            this.blockRedirectConfirm = true;
            this.$router.push({ name: "matches" });
          })
          .catch(() => {
            return;
          });
      }
    },
    cancelInterchange() {
      this.showOverrideModal = false;
      this.showInterchangeModal = false;
      this.selectedPlayers = [];
    },
    editCurrentTeamList(newPlayer1, newPlayer2) {
      // newPlayer1 required, newPlayer2 optional
      const teamList = this.isHomeTeam ? "homeTeamList" : "awayTeamList";
      let newTeamList = this[teamList].player.teamList
        .filter(
          (p) =>
            p._id !== newPlayer1._id &&
            !(newPlayer2 && p._id === newPlayer2._id),
        )
        .concat([newPlayer1]);
      if (newPlayer2) newTeamList = newTeamList.concat([newPlayer2]);

      // this.$set required for Vue reactivity
      this.$set(this[teamList].player, "teamList", newTeamList);
    },
    loadCurrentTeamList(isHome, newPlayer1, newPlayer2) {
      // isHome req, newPlayer1 required, newPlayer2 optional
      // used for mounted()
      const teamList = isHome ? "homeTeamList" : "awayTeamList";
      let newTeamList = this[teamList].player.teamList
        .filter(
          (p) =>
            p._id !== newPlayer1._id &&
            !(newPlayer2 && p._id === newPlayer2._id),
        )
        .concat([newPlayer1]);
      if (newPlayer2) newTeamList = newTeamList.concat([newPlayer2]);

      // this.$set required for Vue reactivity
      this.$set(this[teamList].player, "teamList", newTeamList);
    },
    incorrectInterchange() {
      this.$notify.error({
        title: "Error",
        message: "Interchange Unavailable",
      });
    },
    submitInterchange(selections) {
      /* selections: {
        interchange: { name: String, type: Int }
        mins: String - minutes counting down from game half duration
        secs: String - seconds counting down
      } */
      // HANDLE INVALID PERIOD
      if (!this.period) {
        this.incorrectInterchange();
        return;
      }

      // HANDLE INVALID TIME
      if (
        selections.interchange.type !== 0 &&
        (selections.mins === "" ||
          selections.secs === "" ||
          parseInt(selections.mins) > this.periodLength ||
          parseInt(selections.secs) > 59 ||
          parseInt(selections.mins) < 0 ||
          parseInt(selections.secs) < 0)
      ) {
        this.$notify.error({
          title: "Error",
          message: "Invalid Time",
        });
        return;
      }

      const period = this.period;

      let interchange;
      const newId = generateHash({ length: 17 });
      const oldPlayer1 = this.currentTeamList.player.teamList.find(
        (p) => p._id === this.selectedPlayers[0],
      );
      // Second player is optional
      const oldPlayer2 = this.selectedPlayers[1]
        ? this.currentTeamList.player.teamList.find(
            (p) => p._id === this.selectedPlayers[1],
          )
        : undefined;

      const halfDuration = this.periodLength;
      let min = parseInt(selections.mins);
      let sec = parseInt(selections.secs);

      if (sec > 59 || min > halfDuration || sec < 0 || min < 0) {
        this.$notify.error({
          title: "Error",
          message: "Invalid time duration entered",
        });
        return;
      }
      if (period === 1) {
        // First half
        min = sec === 0 ? halfDuration - min : halfDuration - 1 - min;
        sec = sec === 0 ? 0 : 60 - sec;
      } else if (period === 2) {
        // Second half
        min = sec === 0 ? halfDuration - min : halfDuration - 1 - min;
        sec = sec === 0 ? 0 : 60 - sec;
        min = min + halfDuration;
      } else if (period === 3) {
        // Overtime which counts up instead of down
        min = min + 2 * halfDuration;
      }

      switch (selections.interchange.type) {
        case 0: // User Override - No Interchange added
          if (
            oldPlayer1 &&
            oldPlayer2 &&
            !oldPlayer1.sinBin &&
            !oldPlayer1.sendOff &&
            !oldPlayer2.sinBin &&
            !oldPlayer2.sendOff
          ) {
            // One player in valid field position, other in valid interchange
            const newPlayer1 = Object.assign({}, oldPlayer1, {
              position: oldPlayer2.position,
              hia: undefined,
            });
            const newPlayer2 = Object.assign({}, oldPlayer2, {
              position: oldPlayer1.position,
              hia: undefined,
            });

            this.editCurrentTeamList(newPlayer1, newPlayer2);
          } else if (
            oldPlayer1 &&
            !oldPlayer2 &&
            (oldPlayer1.sinBin || oldPlayer1.sendOff)
          ) {
            // Player Off Field moving back to previous field position
            const newPlayer1 = Object.assign({}, oldPlayer1, {
              sinBin: false,
              sendOff: false,
            });

            this.editCurrentTeamList(newPlayer1);
          } else if (
            oldPlayer1 &&
            !oldPlayer2 &&
            !oldPlayer1.sinBin &&
            !oldPlayer1.sendOff &&
            !oldPlayer1.hia
          ) {
            // Show Override modal to choose if field player is moving to Sin Bin or Off Field
            this.showInterchangeModal = false;
            this.showOverrideModal = true;
            return;
          } else {
            this.incorrectInterchange();
          }
          break;
        case 10: // Regular Interchange - Interchange added
          if (
            oldPlayer1 &&
            oldPlayer2 &&
            // !oldPlayer1.foulPlay &&
            !oldPlayer1.hia &&
            // !oldPlayer1.foulPlayHIA &&
            !oldPlayer1.sinBin &&
            !oldPlayer1.sendOff &&
            //!oldPlayer2.foulPlay &&
            !oldPlayer2.hia &&
            //  !oldPlayer2.foulPlayHIA &&
            !oldPlayer2.sinBin &&
            !oldPlayer2.sendOff
          ) {
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: selections.interchange.type,
              interchangeCounts: true,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                off: oldPlayer1.position.number <= 13 ? oldPlayer1 : oldPlayer2,
                on: oldPlayer1.position.number > 13 ? oldPlayer1 : oldPlayer2,
                period,
              },
            };

            const p1NewPosition =
              oldPlayer1.position.number <= 13 ? "off" : "on";
            const p2newPosition =
              oldPlayer2.position.number <= 13 ? "off" : "on";
            const p1Extra = {
              position: oldPlayer2.position,
            };
            const p2Extra = {
              position: oldPlayer1.position,
            };

            if (
              oldPlayer1.hiaClearedWithin15 ||
              oldPlayer1.hiaClearedOutside15 ||
              oldPlayer1.hiaCleared
            ) {
              interchange.meta[p1NewPosition].hadHIA = true;
              p1Extra.hadHIA = true;
            }

            if (
              oldPlayer2.hiaClearedWithin15 ||
              oldPlayer2.hiaClearedOutside15 ||
              oldPlayer2.hiaCleared
            ) {
              interchange.meta[p2newPosition].hadHIA = true;
              p2Extra.hadHIA = true;
            }

            const newPlayer1 = Object.assign({}, oldPlayer1, p1Extra);
            const newPlayer2 = Object.assign({}, oldPlayer2, p2Extra);
            this.editCurrentTeamList(newPlayer1, newPlayer2);
          } else {
            this.incorrectInterchange();
          }
          break;
        case 11: // HIA Free Interchange
        case 12:
        case 203: // HIA Regular Play
          // Interchange added
          if (
            oldPlayer1 &&
            oldPlayer2 &&
            !oldPlayer1.hia &&
            // !oldPlayer1.foulPlay &&
            !oldPlayer1.foulPlayHIA &&
            !oldPlayer1.sinBin &&
            !oldPlayer1.sendOff &&
            !oldPlayer2.hia &&
            //!oldPlayer2.foulPlay &&
            !oldPlayer2.foulPlayHIA &&
            !oldPlayer2.sinBin &&
            !oldPlayer2.sendOff
          ) {
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: selections.interchange.type,
              interchangeCounts: false,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                off: oldPlayer1.position.number <= 13 ? oldPlayer1 : oldPlayer2,
                on: oldPlayer1.position.number > 13 ? oldPlayer1 : oldPlayer2,
                period,
              },
            };

            const flag =
              selections.interchange.type === 12 ? "hia" : "foulPlayHIA";
            /* Player going out with HIA has _id of interchange added to profile
            so can be found and edited if the HIA ends up counting */
            const newPlayer1 = Object.assign({}, oldPlayer1, {
              position: oldPlayer2.position,
              [flag]: oldPlayer1.position.number <= 13 ? newId : undefined,
            });
            const newPlayer2 = Object.assign({}, oldPlayer2, {
              position: oldPlayer1.position,
              [flag]: oldPlayer2.position.number <= 13 ? newId : undefined,
            });
            this.editCurrentTeamList(newPlayer1, newPlayer2);
          } else {
            this.incorrectInterchange();
          }
          break;
        case 14: // Sin Bin - Interchange added, never counts
          if (oldPlayer1 && !oldPlayer2) {
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: selections.interchange.type,
              interchangeCounts: false,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                off: oldPlayer1,
                period,
              },
            };
            const newPlayer1 = Object.assign({}, oldPlayer1, {
              sinBin: true,
            });
            this.editCurrentTeamList(newPlayer1);
          } else {
            this.incorrectInterchange();
          }
          break;
        case 15: // Sin Bin Return To Field - Interchange added, never counts
          if (oldPlayer1 && !oldPlayer2) {
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: selections.interchange.type,
              interchangeCounts: false,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                on: oldPlayer1,
                period,
              },
            };
            const newPlayer1 = Object.assign({}, oldPlayer1, {
              sinBin: false,
            });
            this.editCurrentTeamList(newPlayer1);
          } else {
            this.incorrectInterchange();
          }
          break;
        case 16: // Send Off - Interchange added, never counts
          if (oldPlayer1 && !oldPlayer2) {
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: selections.interchange.type,
              interchangeCounts: false,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                off: oldPlayer1,
                period,
              },
            };
            const newPlayer1 = Object.assign({}, oldPlayer1, {
              sendOff: true,
            });
            this.editCurrentTeamList(newPlayer1);
          } else {
            this.incorrectInterchange();
          }
          break;
        case 202: // Foul Play - Free Interchange
          if (
            oldPlayer1 &&
            !oldPlayer2 &&
            !oldPlayer1.hia &&
            // !oldPlayer1.foulPlayHIA &&
            //!oldPlayer1.foulPlay &&
            !oldPlayer1.sinBin &&
            !oldPlayer1.sendOff
          ) {
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: selections.interchange.type,
              interchangeCounts: false,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                off: oldPlayer1,
                period,
              },
            };

            const newPlayer1 = Object.assign({}, oldPlayer1, {
              hiaNotCleared: true,
              hia: false,
              foulPlayHIA: true,
            });
            this.editCurrentTeamList(newPlayer1);
            break;
          }
          if (
            oldPlayer1 &&
            oldPlayer2 &&
            !oldPlayer1.hia &&
            // !oldPlayer1.foulPlayHIA &&
            //!oldPlayer1.foulPlay &&
            !oldPlayer1.sinBin &&
            !oldPlayer1.sendOff &&
            !oldPlayer2.hia &&
            // !oldPlayer2.foulPlayHIA &&
            //!oldPlayer2.foulPlay &&
            !oldPlayer2.sinBin &&
            !oldPlayer2.sendOff
          ) {
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: selections.interchange.type,
              interchangeCounts: false,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                off: oldPlayer1.position.number <= 13 ? oldPlayer1 : oldPlayer2,
                on: oldPlayer1.position.number > 13 ? oldPlayer1 : oldPlayer2,
                period,
              },
            };

            const player1Extra = { position: oldPlayer2.position };
            player1Extra.foulPlay = true;
            const player2Extra = { position: oldPlayer1.position };
            const newPlayer1 = Object.assign({}, oldPlayer1, player1Extra);
            const newPlayer2 = Object.assign({}, oldPlayer2, player2Extra);
            this.editCurrentTeamList(newPlayer1, newPlayer2);
          } else {
            this.incorrectInterchange();
          }

          break;

        case 206: //Foul Play Return - Free Interchange
          if (
            oldPlayer1 &&
            oldPlayer2 &&
            !oldPlayer1.hia &&
            !oldPlayer1.sinBin &&
            !oldPlayer1.sendOff &&
            !oldPlayer2.hia &&
            !oldPlayer2.sinBin &&
            !oldPlayer2.sendOff
          ) {
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: selections.interchange.type,
              interchangeCounts: false,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                off: oldPlayer1.position.number <= 13 ? oldPlayer1 : oldPlayer2,
                on: oldPlayer1.position.number > 13 ? oldPlayer1 : oldPlayer2,
                period,
              },
            };

            // Remove every FP / FPHIA before returning
            const player1Extra = { position: oldPlayer2.position };
            const player2Extra = { position: oldPlayer1.position };

            if (oldPlayer1.foulPlayHIA) {
              // If player off field has HIA then give him star on field, remove all other flags
              interchange.meta.on.hadHIA = true;
              player1Extra.hadHIA = true;
            }

            if (oldPlayer2.hadHIA) {
              interchange.meta.off.hadHIA = true;
              player2Extra.hadHIA = true;
            }

            player1Extra.foulPlay = false;
            player1Extra.foulPlayHIA = false;

            const newPlayer1 = Object.assign({}, oldPlayer1, player1Extra);
            const newPlayer2 = Object.assign({}, oldPlayer2, player2Extra);
            this.editCurrentTeamList(newPlayer1, newPlayer2);
          } else {
            this.incorrectInterchange();
          }
          break;
        case 204:
        case 205: //HIA - Cleared within/outside 15'
          if (
            oldPlayer1 &&
            oldPlayer2 &&
            !oldPlayer1.sinBin &&
            !oldPlayer1.sendOff &&
            !oldPlayer2.sinBin &&
            !oldPlayer2.sendOff
          ) {
            const interchangeType = selections.interchange.type;
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: interchangeType,
              interchangeCounts: interchangeType === 204 ? false : true,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                off: oldPlayer1.position.number <= 13 ? oldPlayer1 : oldPlayer2,
                on: oldPlayer1.position.number > 13 ? oldPlayer1 : oldPlayer2,
                period,
              },
            };

            const player1Extra = { position: oldPlayer2.position };
            if (interchangeType === 204) player1Extra.hiaClearedWithin15 = true;
            else player1Extra.hiaClearedOutside15 = true;

            // Remove every hia / FPHIA before returning
            player1Extra.hia = false;
            player1Extra.foulPlayHIA = false;
            const newPlayer1 = Object.assign({}, oldPlayer1, player1Extra);
            const newPlayer2 = Object.assign({}, oldPlayer2, {
              position: oldPlayer1.position,
            });
            this.editCurrentTeamList(newPlayer1, newPlayer2);
          } else {
            this.incorrectInterchange();
          }
          break;

        case 200: {
          // Player off field
          interchange = {
            _id: newId,
            name: selections.interchange.name,
            type: selections.interchange.type,
            interchangeCounts: false,
            teamId: this.selectedTeam._id,
            isHomeTeam: this.isHomeTeam,
            min,
            sec,
            meta: {
              off: oldPlayer1,
              period,
            },
          };

          const newPlayer1 = Object.assign({}, oldPlayer1, {
            playerOffField: true,
          });

          this.editCurrentTeamList(newPlayer1);

          break;
        }
        case 201: {
          // Player on field
          interchange = {
            _id: newId,
            name: selections.interchange.name,
            type: selections.interchange.type,
            interchangeCounts: false,
            teamId: this.selectedTeam._id,
            isHomeTeam: this.isHomeTeam,
            min,
            sec,
            meta: {
              on: oldPlayer1,
              period,
            },
          };

          const newPlayer1 = Object.assign({}, oldPlayer1, {
            playerOffField: false,
          });
          this.editCurrentTeamList(newPlayer1);
          break;
        }
        case 207: {
          // Not Cleared To Return To Play
          interchange = {
            _id: newId,
            name: selections.interchange.name,
            type: selections.interchange.type,
            interchangeCounts: true,
            teamId: this.selectedTeam._id,
            isHomeTeam: this.isHomeTeam,
            min,
            sec,
            meta: {
              off: oldPlayer1,
              period,
            },
          };

          const newPlayer1 = Object.assign({}, oldPlayer1, {
            hiaNotCleared: true,
            hia: false,
            foulPlayHIA: false,
            ruledOut: true,
          });
          this.editCurrentTeamList(newPlayer1);
          break;
        }
        case 208: {
          // Cleared however remaining on bench
          interchange = {
            _id: newId,
            name: selections.interchange.name,
            type: selections.interchange.type,
            interchangeCounts: true,
            teamId: this.selectedTeam._id,
            isHomeTeam: this.isHomeTeam,
            min,
            sec,
            meta: {
              off: oldPlayer1,
              period,
            },
          };

          const newPlayer1 = Object.assign({}, oldPlayer1, {
            hiaCleared: true,
            hia: false,
            foulPlayHIA: false,
          });
          this.editCurrentTeamList(newPlayer1);
          break;
        }
        case 209:
        case 211: {
          // Not Cleared To Return To Play free
          interchange = {
            _id: newId,
            name: selections.interchange.name,
            type: selections.interchange.type,
            interchangeCounts: false,
            teamId: this.selectedTeam._id,
            isHomeTeam: this.isHomeTeam,
            min,
            sec,
            meta: {
              off: oldPlayer1,
              period,
            },
          };
          const newData = {};
          const foulPlayHIANotCleared = oldPlayer1.foulPlayHIA ? true : null;
          if (interchange.type === 209) {
            newData.hiaNotCleared = true;
            newData.hia = false;
            newData.foulPlayHIA = false;
            if (foulPlayHIANotCleared)
              newData.foulPlayHIANotCleared = foulPlayHIANotCleared;
          }
          newData.ruledOut = true;
          const newPlayer1 = Object.assign({}, oldPlayer1, newData);
          this.editCurrentTeamList(newPlayer1);
          break;
        }
        case 210: {
          // Cleared however remaining on bench free
          interchange = {
            _id: newId,
            name: selections.interchange.name,
            type: selections.interchange.type,
            interchangeCounts: false,
            teamId: this.selectedTeam._id,
            isHomeTeam: this.isHomeTeam,
            min,
            sec,
            meta: {
              off: oldPlayer1,
              period,
            },
          };

          const newPlayer1 = Object.assign({}, oldPlayer1, {
            hiaCleared: true,
            hia: false,
            foulPlayHIA: false,
          });
          this.editCurrentTeamList(newPlayer1);
          break;
        }
        case 999: {
          // Activate 18th Player
          if (
            oldPlayer1 &&
            oldPlayer2 &&
            !oldPlayer1.hia &&
            !oldPlayer1.sinBin &&
            !oldPlayer1.sendOff &&
            oldPlayer1.position.abbreviation === "18M" &&
            oldPlayer2.ruledOut &&
            (oldPlayer2.hiaNotCleared ||
              oldPlayer2.foulPlay ||
              oldPlayer2.foulPlayHIANotCleared)
          ) {
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: selections.interchange.type,
              interchangeCounts: false,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                on: oldPlayer1,
                off: oldPlayer2,
                period,
              },
            };
            const newPlayer1 = Object.assign({}, oldPlayer1, {
              activatedReplacementPlayer: true,
              playerOffField: false,
              position: {
                abbreviation: "18",
                name: "Interchange",
                number: 99,
                order: 99,
              },
            });
            this.editCurrentTeamList(newPlayer1);
          } else {
            this.incorrectInterchange();
          }
          break;
        }
        case 1000: {
          if (
            oldPlayer1 &&
            oldPlayer2 &&
            !oldPlayer1.hia &&
            !oldPlayer1.sinBin &&
            !oldPlayer1.sendOff &&
            !oldPlayer2.hia &&
            !oldPlayer2.sinBin &&
            !oldPlayer2.sendOff &&
            (oldPlayer1.position.abbreviation === "18M" ||
              oldPlayer2.position.abbreviation === "18M")
          ) {
            interchange = {
              _id: newId,
              name: selections.interchange.name,
              type: selections.interchange.type,
              interchangeCounts: false,
              teamId: this.selectedTeam._id,
              isHomeTeam: this.isHomeTeam,
              min,
              sec,
              meta: {
                off:
                  oldPlayer1.position.abbreviation !== "18M"
                    ? oldPlayer1
                    : oldPlayer2,
                on:
                  oldPlayer1.position.abbreviation === "18M"
                    ? oldPlayer1
                    : oldPlayer2,
                period,
              },
            };

            const p1Extra = {};
            const p2Extra = {};

            if (oldPlayer2.position.abbreviation !== "18M") {
              p1Extra.position = oldPlayer2.position;
              p1Extra.activatedReplacementPlayer = true;
              p2Extra.ruledOut = true;
            }
            if (oldPlayer1.position.abbreviation !== "18M") {
              p2Extra.position = oldPlayer1.position;
              p2Extra.activatedReplacementPlayer = true;
              p1Extra.ruledOut = true;
            }

            const newPlayer1 = Object.assign({}, oldPlayer1, p1Extra);
            const newPlayer2 = Object.assign({}, oldPlayer2, p2Extra);

            this.editCurrentTeamList(newPlayer1, newPlayer2);
          } else {
            this.incorrectInterchange();
          }
          break;
        }
        default:
          this.incorrectInterchange();
      }

      if (interchange) {
        /* Update completedInterchanges (reactively) if new Interchange created,
        automatically sent to API as completedInterchanges is watched */
        this.completedInterchanges = this.completedInterchanges.concat([
          interchange,
        ]);
      }

      this.showInterchangeModal = false;
      this.selectedPlayers = [];
    },
    submitOverride(interchange) {
      // Only handles Override Interchanges from field to Off Field as can't be inferred programatically
      const oldPlayer1 = this.currentTeamList.player.teamList.find(
        (p) => p._id === this.selectedPlayers[0],
      );
      let newPlayer1;
      if (interchange.type === 14) {
        newPlayer1 = Object.assign({}, oldPlayer1, {
          sinBin: true,
        });
      }
      if (interchange.type === 16) {
        newPlayer1 = Object.assign({}, oldPlayer1, {
          sendOff: true,
        });
      }

      if (newPlayer1) {
        this.editCurrentTeamList(newPlayer1);
        this.showOverrideModal = false;
        this.selectedPlayers = [];
      } else {
        this.incorrectInterchange();
      }
    },
    sendGameState() {
      this.$http
        .post(
          `/nrl/api/v1/elite/matches/${this.currentMatch._id}/game-states`,
          {
            gameStates: this.completedGameStates,
          },
        )
        .then(() => {
          this.apiRequestFailed = Object.assign({}, this.apiRequestFailed, {
            gameState: false,
          });
        })
        .catch(() => {
          this.apiRequestFailed = Object.assign({}, this.apiRequestFailed, {
            gameState: true,
          });
        });
    },
    sendInterchange(refreshMatch = false) {
      this.$http
        .post(`/nrl/api/v1/elite/matches/${this.currentMatch._id}/movements`, {
          playerMovements: this.completedInterchanges,
        })
        .then(() => {
          this.apiRequestFailed = Object.assign({}, this.apiRequestFailed, {
            interchange: false,
          });
          if (refreshMatch) {
            this.$nextTick(() => {
              this.refreshMatch(this.currentMatch._id);
            });
          }
        })
        .catch(() => {
          this.apiRequestFailed = Object.assign({}, this.apiRequestFailed, {
            interchange: true,
          });
        });
    },
    resendApiRequests() {
      if (this.apiRequestFailed.gameState) this.sendGameState();
      if (this.apiRequestFailed.interchange) this.sendInterchange();
    },
    preventNav(e) {
      // note: doesn't seem to work on iPad (Chrome or Safari) with refresh
      e.preventDefault();
      e.returnValue = "Are you sure you want to leave?";
    },
    refreshMatch(id) {
      this.blockSendInterchange = true;
      this.$store.commit("LOADING", true);
      this.$http
        .get(`/nrl/api/v1/elite/matches/${id}`)
        .then((response) => {
          this.currentMatch = response.data.data;
          this.$store.commit("match/CURRENT_MATCH", response.data.data);
          if (this.selectedTeam._id === this.currentMatch.homeTeam._id) {
            this.selectedTeam = this.currentMatch.homeTeam;
          } else if (this.selectedTeam._id === this.currentMatch.awayTeam._id) {
            this.selectedTeam = this.currentMatch.awayTeam;
          } else {
            this.selectedTeam = this.currentMatch.homeTeam;
          }

          if (
            this.currentMatch.gameStates &&
            this.currentMatch.gameStates.length > 0
          ) {
            this.completedGameStates = this.currentMatch.gameStates;
            this.confirmAwayStatus = true;
            this.confirmHomeStatus = true;
          }
          this.completedInterchanges =
            this.currentMatch.playerMovements &&
            this.currentMatch.playerMovements.length > 0
              ? this.currentMatch.playerMovements
              : [];
          this.$store.commit(
            "match/UPDATE_INTERCHANGES",
            this.completedInterchanges,
          );
          const getHomeTeamList = this.$http.get(
            `/nrl/api/v1/elite/teamlists/match/${this.currentMatch._id}/team/${this.currentMatch.homeTeam._id}`,
          );
          const getAwayTeamList = this.$http.get(
            `/nrl/api/v1/elite/teamlists/match/${this.currentMatch._id}/team/${this.currentMatch.awayTeam._id}`,
          );

          Promise.all([getHomeTeamList, getAwayTeamList])
            .then(([homeResponse, awayResponse]) => {
              if (homeResponse) {
                this.homeTeamList = homeResponse.data.data;
              }
              if (awayResponse) {
                this.awayTeamList = awayResponse.data.data;
              }

              // Update Current TL based on completed interchanges
              if (
                this.completedInterchanges &&
                this.completedInterchanges.length > 0
              ) {
                this.completedInterchanges.forEach((interchange) => {
                  switch (interchange.type) {
                    case 10: // Regular Interchange - Interchange added
                      if (interchange.meta.off && interchange.meta.on) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            position: interchange.meta.on.position,
                            hadHIA: interchange.meta.off.hadHIA,
                          },
                        );
                        const newPlayer2 = Object.assign(
                          {},
                          interchange.meta.on,
                          {
                            position: interchange.meta.off.position,
                            hadHIA: interchange.meta.on.hadHIA,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                          newPlayer2,
                        );
                      }
                      break;
                    case 11: // HIA Free Interchange
                    case 12:
                    case 203: // HIA Regular Play
                      if (interchange.meta.off && interchange.meta.on) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            position: interchange.meta.on.position,
                            hia:
                              interchange.meta.off.position.number <= 13 &&
                              (interchange.type === 11 ||
                                interchange.type === 12)
                                ? interchange._id
                                : undefined,
                            foulPlayHIA:
                              interchange.type === 203 &&
                              interchange.meta.off.position.number <= 13
                                ? true
                                : undefined,
                          },
                        );
                        const newPlayer2 = Object.assign(
                          {},
                          interchange.meta.on,
                          {
                            position: interchange.meta.off.position,
                            hia:
                              interchange.meta.on.position.number <= 13 &&
                              (interchange.type === 11 ||
                                interchange.type === 12)
                                ? interchange._id
                                : undefined,
                            foulPlayHIA:
                              interchange.type === 203 &&
                              interchange.meta.on.position.number <= 13
                                ? true
                                : undefined,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                          newPlayer2,
                        );
                      }
                      break;
                    case 13: // HIA Return to Field - Interchange added, never counts
                      if (interchange.meta.off && interchange.meta.on) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            position: interchange.meta.on.position,
                            hia: undefined,
                          },
                        );
                        const newPlayer2 = Object.assign(
                          {},
                          interchange.meta.on,
                          {
                            position: interchange.meta.off.position,
                            hia: undefined,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                          newPlayer2,
                        );
                      }
                      break;
                    case 14: // Sin Bin - Interchange added, never counts
                      if (interchange.meta.off) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            sinBin: true,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                        );
                      }
                      break;
                    case 15: // Sin Bin Return To Field - Interchange added, never counts
                      if (interchange.meta.on) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.on,
                          {
                            sinBin: false,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                        );
                      }
                      break;
                    case 16: // Send Off - Interchange added, never counts
                      if (interchange.meta.off) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            sendOff: true,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                        );
                      }
                      break;
                    case 202: // Foul Play - Free Interchange && Foul Play Return - Free Interchange
                    case 206:
                      if (interchange.meta.off && interchange.meta.on) {
                        const player1Extra = {
                          position: interchange.meta.on.position,
                          foulPlay:
                            interchange.type === 206 &&
                            interchange.meta.on.position.number <= 13
                              ? false
                              : undefined,
                          foulPlayHIA:
                            interchange.type === 206 &&
                            interchange.meta.on.position.number <= 13
                              ? false
                              : undefined,
                          hadHIA:
                            interchange.type === 206 &&
                            interchange.meta.off.hadHIA,
                        };

                        const player2Extra = {
                          position: interchange.meta.off.position,
                          foulPlay:
                            interchange.type === 206 &&
                            interchange.meta.off.position.number <= 13
                              ? false
                              : undefined,
                          foulPlayHIA:
                            interchange.type === 206 &&
                            interchange.meta.off.position.number <= 13
                              ? false
                              : undefined,
                          hadHIA:
                            interchange.type === 206 &&
                            interchange.meta.on.hadHIA,
                        };

                        if (interchange.type === 202) {
                          if (interchange.meta.off.position.number <= 13) {
                            player1Extra.foulPlay = true;
                          } else {
                            player2Extra.foulPlay = true;
                          }
                        }

                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          player1Extra,
                        );

                        const newPlayer2 = Object.assign(
                          {},
                          interchange.meta.on,
                          player2Extra,
                        );

                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                          newPlayer2,
                        );
                      }
                      break;
                    case 204: // HIA - Cleared to return to field within/outside of 15'
                    case 205:
                      if (interchange.meta.off && interchange.meta.on) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            position: interchange.meta.on.position,
                            hiaClearedWithin15:
                              interchange.type === 204 &&
                              interchange.meta.on.position.number <= 13
                                ? true
                                : undefined,
                            hiaClearedOutside15:
                              interchange.type === 205 &&
                              interchange.meta.on.position.number <= 13
                                ? true
                                : undefined,
                            hia: false,
                            foulPlayHIA: false,
                          },
                        );

                        const newPlayer2 = Object.assign(
                          {},
                          interchange.meta.on,
                          {
                            position: interchange.meta.off.position,
                            hiaClearedWithin15:
                              interchange.type === 204 &&
                              interchange.meta.off.position.number <= 13
                                ? true
                                : undefined,
                            hiaClearedOutside15:
                              interchange.type === 205 &&
                              interchange.meta.off.position.number <= 13
                                ? true
                                : undefined,
                            hia: false,
                            foulPlayHIA: false,
                          },
                        );

                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                          newPlayer2,
                        );
                      }
                      break;
                    case 200: // Player Off Field
                      if (interchange.meta.off) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            playerOffField: true,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                        );
                      }
                      break;
                    case 201: // Player On Field
                      if (interchange.meta.on) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.on,
                          {
                            playerOffField: false,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                        );
                      }
                      break;
                    case 207:
                      if (interchange.meta.off) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            hia: false,
                            foulPlayHIA: false,
                            hiaNotCleared: true,
                            ruledOut: true,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                        );
                      }
                      break;
                    case 208:
                      if (interchange.meta.off) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            hia: false,
                            foulPlayHIA: false,
                            hiaCleared: true,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                        );
                      }
                      break;
                    case 209:
                      if (interchange.meta.off) {
                        const { foulPlayHIA } = interchange.meta.off;
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            hia: false,
                            foulPlayHIA: false,
                            hiaNotCleared: true,
                            foulPlayHIANotCleared: foulPlayHIA,
                            ruledOut: true,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                        );
                      }
                      break;
                    case 211:
                      if (interchange.meta.off) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            ruledOut: true,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                        );
                      }
                      break;
                    case 999:
                      if (interchange.meta.on) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.on,
                          {
                            activatedReplacementPlayer: true,
                            playerOffField: false,
                            position: {
                              abbreviation: "18",
                              name: "Interchange",
                              number: 99,
                              order: 99,
                            },
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                        );
                      }
                      break;
                    case 1000:
                      if (interchange.meta.off && interchange.meta.on) {
                        const newPlayer1 = Object.assign(
                          {},
                          interchange.meta.off,
                          {
                            ruledOut: true,
                          },
                        );
                        const newPlayer2 = Object.assign(
                          {},
                          interchange.meta.on,
                          {
                            position: interchange.meta.off.position,
                            activatedReplacementPlayer: true,
                          },
                        );
                        this.loadCurrentTeamList(
                          interchange.isHomeTeam,
                          newPlayer1,
                          newPlayer2,
                        );
                      }
                      break;
                    default:
                      break;
                  }
                });
              }
              this.$store.commit("LOADING", false);
            })
            .catch((e) => {
              this.$notify.error({
                title: "Error",
                message:
                  e.response && e.response.data && e.response.data.message
                    ? e.response.data.message
                    : "There has been an error",
              });
              this.$store.commit("LOADING", false);
            });
        })
        .catch((e) => {
          this.$notify.error({
            title: "Error",
            message:
              e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : "There has been an error",
          });
          this.$store.commit("LOADING", false);
          if (e.response.status === 403) this.$router.push("/landing");
        });
    },
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    window.addEventListener("online", this.toggleNetwork);
    window.addEventListener("offline", this.toggleNetwork);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
    window.removeEventListener("online", this.toggleNetwork);
    window.removeEventListener("offline", this.toggleNetwork);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.currentMatch || this.blockRedirectConfirm) {
      next();
    } else if (!window.confirm("Are you sure you want to leave this page?")) {
      return;
    }
    next();
  },
  watch: {
    completedInterchanges(newInterchanges, oldInterchanges) {
      if (this.blockSendInterchange) {
        this.blockSendInterchange = false;
      } else {
        const refreshMatch =
          newInterchanges.length < oldInterchanges.length &&
          this.currentMatch._id;
        this.sendInterchange(refreshMatch);
      }
    },
  },
  mounted() {
    const { id } = this.$route.params;
    this.refreshMatch(id);
  },
};
</script>

<style lang="scss" scoped>
$number-circle-factor: 0.8;
.canvas {
  position: relative;
  width: 356px;
  height: 380px;
  background-color: white;
  margin: auto;

  .number-circle {
    flex-shrink: 0;
    width: 36px * $number-circle-factor;
    height: 36px * $number-circle-factor;
    padding: 8px * $number-circle-factor;
    margin: 0;

    color: #666;

    font: 22px * $number-circle-factor Arial, sans-serif;
    @media (max-width: $tablet) {
      width: 32px * $number-circle-factor;
      height: 32px * $number-circle-factor;
      font-size: 12px;
    }

    &.green {
      border-color: $primary;
    }

    &.available {
      border-color: $primary-highlight;
      color: $primary-highlight;
    }
  }
}

.b1 {
  max-width: 200px;
}
.bt {
  letter-spacing: 3px;
  font-weight: bold;
}
.rel {
  position: relative;
}
.footer-wrap {
  padding: 7px 20px;
  display: flex;
  flex-wrap: wrap-reverse;
}

.float-button-left {
  position: absolute;
  left: 10px;
  top: 10px;
}

.player {
  position: absolute;
  transform: translate(20px, 16px);

  .player-name-wrap {
    position: relative;

    .player-name {
      position: absolute;
      width: 200%;
      left: -50%;
      text-align: center;
      font-size: 0.7em;
      color: grey;
      // white-space: nowrap;
      @media (max-width: $tablet) {
        font-size: 0.6em;
      }
    }
  }
}
.nowrap {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: nowrap;
}

.name-tag {
  box-sizing: border-box;
  text-align: left;

  .profile-name {
    margin: auto;

    span {
      text-transform: uppercase;
      color: $primary;
      font-size: 0.75rem;
    }
  }

  .red {
    text-transform: uppercase;
    color: red;
    margin: auto;
  }

  &.highlight {
    text-align: center;
    text-transform: uppercase;
    border: 2px solid $primary-highlight;
    color: $primary-highlight;
  }

  &.available {
    color: $primary-highlight;
  }
}

.confirm-modal {
  text-align: left;
}

.status {
  position: fixed;
  top: 0px;
  left: calc(50% - 100px);
  border-left: 3px solid $primary;
  border-right: 3px solid $primary;
  border-bottom: 3px solid $primary;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  z-index: 10;

  .online {
    width: 50px;
    height: 50px;
    background-color: $primary-highlight;
    border-right: 2px solid white;
    font-size: 24px;

    &.off {
      background-color: red;
    }
  }

  .saved {
    height: 50px;
    width: 150px;
    background-color: $primary-highlight;
    text-transform: uppercase;
    font-weight: bold;

    &.off {
      background-color: red;
    }
  }

  .auto-white {
    margin: auto;
    color: white;
  }
}
.btn-small {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 60px;
  background-color: #098255;
  border: 2px solid #098255;
  color: white;
  text-transform: uppercase;
  border-radius: 2px;
  cursor: pointer;
  height: 24px;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 4px 4px 0px 4px;
  margin-left: 4px;
}
.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 40px;
  max-width: 120px;
  background-color: #098255;
  border: 2px solid #098255;
  color: white;
  text-transform: uppercase;
  border-radius: 2px;
  cursor: pointer;
  min-height: 24px;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 4px 12px 0px 12px;
  margin-left: 12px;
}
.override {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 40px;
  max-width: 120px;
  color: #098255;
  text-transform: uppercase;
  min-height: 24px;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 4px 12px 0px 12px;
  margin-left: 12px;
}
</style>
