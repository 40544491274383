<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container short">
        <div class="modal-header">
          <div class="head">
            <div class="start">
              <div class="breadcrumb">
                <p>Interchange</p>

                <span class="slash">/</span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-body">
          <div class="align-text-center">
            <div class="d-flex players">
              <div v-if="selectedPlayers[0]" class="card name-tag d-flex mt-10">
                <div class="number-circle">
                  <b>{{ selectedPlayers[0].shirtNum }}</b>
                </div>
                <div class="profile-name flex-1">
                  {{ selectedPlayers[0].firstName }}
                  <b>{{ selectedPlayers[0].lastName }}</b>
                  <br />
                  <span>{{ selectedPlayers[0].position.name }}</span>
                </div>
              </div>
              <div v-if="selectedPlayers[1]" class="card name-tag d-flex mt-10">
                <div class="number-circle">
                  <b>{{ selectedPlayers[1].shirtNum }}</b>
                </div>
                <div class="profile-name flex-1">
                  {{ selectedPlayers[1].firstName }}
                  <b>{{ selectedPlayers[1].lastName }}</b>
                  <br />
                  <span>{{ selectedPlayers[1].position.name }}</span>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="squares d-flex">
                <div>
                  <div class="square">
                    <input v-model="mins" type="number" />
                  </div>
                  <div class="small-text-wrap">
                    <span class="small-text">mins</span>
                  </div>
                </div>
                <div class="stuff" />
                <div>
                  <div class="square">
                    <input v-model="secs" type="number" />
                  </div>
                  <div class="small-text-wrap">
                    <span class="small-text">secs</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex-column loose-column-button-wrapper">
              <button
                class="transparent"
                v-for="i in choices"
                :key="i.type"
                @click="selected = i"
                :class="{ selected: selected && selected.type === i.type }"
              >
                {{ i.name }}
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div class="modal-footer">
          <hr />
          <div class="buttons">
            <button class="loose" @click="submit" :disabled="disableButton">
              ok
            </button>
            <button class="loose transparent" @click="$emit('close')">
              cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { interchanges } from '@/utils/constants';

  export default {
    name: 'InterchangeModal',
    props: ['selectedPlayers'],
    data() {
      return {
        mins: '',
        secs: '',
        selected: undefined,
        interchanges,
      };
    },
    computed: {
      choices() {
        const isInterChange = this.selectedPlayers.length === 2;
        const playerOffField =
          (this.selectedPlayers.length === 1 &&
            this.selectedPlayers[0].position.number >= 13 && this.selectedPlayers[0].position.abbreviation !== '18M') ||
          this.selectedPlayers[0].playerOffField ||
          this.selectedPlayers[0].sinBin;
        const playerOnField =
          this.selectedPlayers.length === 1 &&
          this.selectedPlayers[0].position.number <= 13 &&
          !this.selectedPlayers[0].playerOffField &&
          !this.selectedPlayers[0].sinBin &&
          !this.selectedPlayers[0].ruledOut;
        const playerReplacement = 
          this.selectedPlayers.find(pl => pl.position.abbreviation === '18M') ? true : false;
        const offThenOn =
          isInterChange &&
          this.selectedPlayers[0].position.name === 'Interchange';
        const onThenOff =
          isInterChange &&
          this.selectedPlayers[1].position.name === 'Interchange' &&
          !this.selectedPlayers[1].hiaNotCleared &&
          !this.selectedPlayers[1].ruledOut;
        const playerReplacementThenBench =
          isInterChange &&
          playerReplacement &&
          this.selectedPlayers[0].position.abbreviation === "18M" &&
          this.selectedPlayers[1].ruledOut;

        let choices = [];
        if (
          this.selectedPlayers.length === 1 &&
          this.selectedPlayers[0].sendOff
        )
          return [{ name: 'User Override', type: 0 }]; // Ignore Send Offs

        if (onThenOff) {
          // Select an on field player then an off field player
          choices = this.interchanges.filter(
            (i) =>
              i.type === 10 || i.type === 12 || i.type === 202 || i.type === 203
          );
        } else if (offThenOn) {
          // Select an off field player then an on field player
          const [offFieldPlayer] = this.selectedPlayers;
          const { hia, foulPlayHIA, foulPlay } = offFieldPlayer;
          choices = this.interchanges.filter((i) => i.type === 10);
          if (hia) {
            // HIA - Return Within 15'
            choices = choices.concat(
              this.interchanges.filter((i) => i.type === 204)
            );
            choices = choices.concat(
              this.interchanges.filter((i) => i.type === 205)
            );
          }
          if (foulPlay || foulPlayHIA) {
            // Free Interchange
            choices = choices.concat(
              this.interchanges.filter((i) => i.type === 206)
            );
          }
          choices = choices.concat([{ name: 'User Override', type: 0 }]);
        } else if (playerOnField) {
          // Select a player on field and select Move Here in Player Off Field section
          choices = this.interchanges.filter(
            (i) => i.type === 14 || i.type === 16 || i.type === 200
          );
          choices = choices.concat([{ name: 'User Override', type: 0 }]);
        } else if (playerOffField) {
          // Select a player in the Player Off Field section
          const [currentPlayer] = this.selectedPlayers;
          if (
            !currentPlayer.hiaNotCleared &&
            !currentPlayer.sinBin &&
            !currentPlayer.foulPlayHIA &&
            !currentPlayer.foulPlay &&
            !currentPlayer.hia && 
            !currentPlayer.ruledOut
          ) {
            choices = choices.concat(
              this.interchanges.filter((i) => i.type === 201)
            );
          }

          const { hia, sinBin, foulPlayHIA, hiaNotCleared, foulPlay, ruledOut } = currentPlayer;
          if (hia) {
            // Display HIA Clearing options when there's a HIA
            choices = choices.concat(
              this.interchanges.filter((i) => i.type === 207 || i.type === 208)
            );
            choices = choices.concat(
              this.interchanges.filter((i) => i.type === 202 || i.type === 202)
            );
          }
          if (foulPlayHIA) {
            // Display HIA Clearing options when there's a HIA
            choices = choices.concat(
              this.interchanges.filter((i) => i.type === 209 || i.type === 210)
            );
          }
          if (sinBin) {
            // Only display SinBin return when it's Sin Bin
            choices = choices.concat(
              this.interchanges.filter((i) => i.type === 15)
            );
          }
          if(foulPlay && !ruledOut){
            choices = choices.concat(
              this.interchanges.filter((i) => i.type === 211)
            );
          }

          if (!hiaNotCleared && !ruledOut) {
            choices = choices.concat([{ name: 'User Override', type: 0 }]);
          }
        }else if(playerReplacement){
          // Select Replacement Player 18th Man
          const [currentPlayer] = this.selectedPlayers;
          if(playerReplacementThenBench) {
            choices = this.interchanges.filter(
              (i) => i.type === 999
            );
          }else{
            choices = this.interchanges.filter(
              i => i.type === 1000
            )
          }
        }

        return choices;
      },
      disableButton() {
        if (this.selected === undefined) return true;
        else return false;
      },
    },
    methods: {
      submit() {
        this.$emit('submit', {
          mins: this.mins,
          secs: this.secs,
          interchange: this.selected,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-container {
    max-width: 600px;
  }
  .vertical-center {
    margin-top: auto;
    margin-bottom: auto;
  }
  .big-input {
    width: 100px;
    height: 40px;
    font-size: 1.5em;
    border: 2px solid black;
    margin-left: 10px;
    text-align: center;
    font-weight: bold;
    color: black;
  }
  .cap {
    font-size: 1.2em;
    color: grey;
  }
  button {
    width: 130px;
  }

  .line {
    display: flex;
    justify-content: space-between;
  }
  .name {
    font-size: 1.5em;
    display: flex;
  }
  .head {
    height: 50px;
    display: flex;
    margin-bottom: 10px;

    > * {
      margin-top: auto;
      margin-bottom: auto;
    }

    .start {
      flex-grow: 1;
      text-align: left;
    }
  }

  .loose-column-button-wrapper {
    > button,
    div {
      margin-top: 15px;
      word-wrap: break-word; /* All browsers since IE 5.5+ */
      overflow-wrap: break-word; /* Renamed property in CSS3 draft spec */
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      font-size: 0.66rem;
    }
  }
  .align-text-center {
    margin-left: auto;
    margin-right: auto;
  }

  .squares {
    margin: auto;
  }
  .square {
    display: flex;
    width: 70px;
    height: 70px;
    background: #ededed;
    input[type='number'] {
      appearance: none;
      -moz-appearance: textfield;
      width: 100%;
      font-size: 1.4em;
      font-weight: bold;
      margin: auto;
      text-align: center;
      color: $secondary;
      border: none;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
      }
    }
  }
  .small-text-wrap {
    width: 100%;
    text-align: left;
    .small-text {
      text-transform: uppercase;
      font-size: 0.7em;
    }
  }
  .stuff {
    width: 30px;
  }
  .name-tag {
    text-align: left;
    width: 30%;

    .profile-name {
      margin: auto;

      span {
        text-transform: uppercase;
        color: $primary;
        font-size: 0.75rem;
      }
    }
  }
  .players {
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .number-circle {
    margin-top: auto;
    margin-bottom: auto;
  }
</style>
