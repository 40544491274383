var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.Default,{tag:"component"},[(_vm.showFeedModal)?_c('FeedSideModal',{attrs:{"selectedTeamId":_vm.selectedTeam._id},on:{"close":function($event){_vm.showFeedModal = false}}}):_vm._e(),(_vm.showInterchangeModal)?_c('InterchangeModal',{attrs:{"selectedPlayers":_vm.selectedPlayers.map((s) =>
        _vm.currentTeamList.player.teamList.find((p) => p._id === s),
      )},on:{"submit":_vm.submitInterchange,"close":_vm.cancelInterchange}}):_vm._e(),(_vm.showOverrideModal)?_c('OverrideModal',{attrs:{"selectedPlayers":_vm.selectedPlayers.map((s) =>
        _vm.currentTeamList.player.teamList.find((p) => p._id === s),
      )},on:{"submit":_vm.submitOverride,"close":_vm.cancelInterchange}}):_vm._e(),_c('template',{slot:"buttons"},[_c('div',{staticClass:"status d-flex"},[_c('div',{staticClass:"online d-flex",class:{ off: !_vm.networkOnline }},[_c('div',{staticClass:"auto-white"},[_c('i',{staticClass:"el-icon-sort"})])]),_c('div',{staticClass:"saved d-flex",class:{
          off: _vm.apiRequestFailed.gameState || _vm.apiRequestFailed.interchange,
        },on:{"click":_vm.resendApiRequests}},[_c('div',{staticClass:"auto-white"},[_vm._v(" "+_vm._s(_vm.apiRequestFailed.gameState || _vm.apiRequestFailed.interchange ? "Retry Save" : "Saved")+" ")])])]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"loose thin",on:{"click":function($event){return _vm.$router.push('/landing')}}},[_vm._v(" Dashboard ")]),_c('button',{staticClass:"loose transparent thin",on:{"click":_vm.$logout}},[_vm._v("Log Out")])])]),_c('template',{slot:"foot"},[_c('div',{staticClass:"footer-wrap"},[_c('div',{staticClass:"flex-1 buttons"},[_c('button',{staticClass:"b1 transparent loose",on:{"click":function($event){return _vm.$router.push({ name: 'matches' })}}},[_vm._v(" Matches ")])]),_c('div',{staticClass:"buttons align-right"},[(!(_vm.confirmHomeStatus && _vm.confirmAwayStatus))?_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"b1 loose",attrs:{"disabled":_vm.disableSubmitButton},on:{"click":_vm.submitTeamList}},[_vm._v(" Confirm List ")])]):_c('div',{staticClass:"nowrap"},_vm._l((_vm.eliteGameStates),function(gameState,i){return _c('button',{key:i,staticClass:"b1 loose",class:{
              transparent: !(
                _vm.latestGameState && _vm.latestGameState.type === gameState.type
              ),
            },on:{"click":function($event){return _vm.setGameState(gameState)}}},[_vm._v(" "+_vm._s(gameState.name)+" ")])}),0)])])]),(_vm.currentMatch && _vm.currentMatch.venue)?_c('Match',{staticClass:"rel",attrs:{"place":_vm.currentMatch.venue.name,"team1":_vm.currentMatch.homeTeam.name,"team2":_vm.currentMatch.awayTeam.name,"club1":_vm.currentMatch.homeTeam.orgtree.club,"club2":_vm.currentMatch.awayTeam.orgtree.club,"score1":_vm.currentMatch.scores ? _vm.currentMatch.scores.homeTeam : undefined,"score2":_vm.currentMatch.scores ? _vm.currentMatch.scores.awayTeam : undefined,"time":_vm.matchTime(_vm.currentMatch.dateTime),"title":`Round ${_vm.currentMatch.round.number} ${_vm.matchDate(_vm.currentMatch.dateTime)}`}},[_c('div',{staticClass:"float-button-left"},[_c('button',{staticClass:"transparent",on:{"click":_vm.feedOpen}},[_c('span',{staticClass:"bt"},[_vm._v("feed")])])])]):_vm._e(),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"w-3 column"},[_c('div',{staticClass:"mini-header"},[_vm._v(" team selection ")]),_c('form',{staticStyle:{"padding":"10px 0px","display":"flex","flex-wrap":"wrap","justify-content":"end","align-items":"flex-start"},attrs:{"id":"overrideHome"},on:{"submit":function($event){return _vm.submitTeamListOverride(_vm.currentMatch.homeTeam._id)}}},[(_vm.currentMatch)?_c('div',{staticClass:"card clickable",class:{
            selected: _vm.selectedTeam._id === _vm.currentMatch.homeTeam._id,
          },staticStyle:{"flex":"1","min-width":"66%"},on:{"click":function($event){_vm.selectedTeam = _vm.currentMatch.homeTeam}}},[_c('div',[_vm._v(" "+_vm._s(_vm.interchangeNumber.home)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.currentMatch.homeTeam.name)+" ")])]):_vm._e(),(_vm.homeTeamList && _vm.homeTeamList.override)?_c('div',{staticClass:"override",staticStyle:{"margin-top":"8px"}},[_vm._v(" REL ")]):_vm._e(),(_vm.homeTeamList && !_vm.homeTeamList.override)?_c('label',{staticClass:"btn",staticStyle:{"margin-top":"8px"},on:{"click":function($event){return _vm.overrideCheck('Home')}}},[_vm._v(" OVR ")]):_vm._e()]),_c('form',{staticStyle:{"padding":"10px 0px","display":"flex","flex-wrap":"wrap","justify-content":"flex-end","align-items":"flex-start"},attrs:{"id":"overrideAway"},on:{"submit":function($event){return _vm.submitTeamListOverride(_vm.currentMatch.awayTeam._id)}}},[(_vm.currentMatch)?_c('div',{staticClass:"card clickable",class:{
            selected: _vm.selectedTeam._id === _vm.currentMatch.awayTeam._id,
          },staticStyle:{"flex":"1","min-width":"66%"},on:{"click":function($event){_vm.selectedTeam = _vm.currentMatch.awayTeam}}},[_c('div',[_vm._v(" "+_vm._s(_vm.interchangeNumber.away)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.currentMatch.awayTeam.name)+" ")])]):_vm._e(),(_vm.awayTeamList && _vm.awayTeamList.override)?_c('div',{staticClass:"override",staticStyle:{"margin-top":"8px"}},[_vm._v(" REL ")]):_vm._e(),(_vm.awayTeamList && !_vm.awayTeamList.override)?_c('label',{staticClass:"btn",staticStyle:{"margin-top":"8px"},on:{"click":function($event){return _vm.overrideCheck('Away')}}},[_vm._v(" OVR ")]):_vm._e()])]),_c('div',{staticClass:"w-0 column"},[_c('div',{staticClass:"canvas"},_vm._l((_vm.startingPositions),function(position){return _c('div',{key:position.number,staticClass:"player",style:(_vm.getPosition(position.number))},[_c('div',{staticClass:"number-circle green clickable",class:{
              selected:
                _vm.players[position.number] &&
                _vm.selectedPlayers.length > 0 &&
                _vm.selectedPlayers.includes(_vm.players[position.number]._id),
              available:
                (_vm.players[position.number] &&
                  _vm.playersInterchange.filter(
                    (i) => _vm.selectedPlayers[0] === i._id,
                  ).length !== 0) ||
                (_vm.players[position.number] &&
                  _vm.selectedPlayers.length > 0 &&
                  Object.values(_vm.replacementPlayer).find(
                    (n) => n._id === _vm.selectedPlayers[0],
                  )),
            },on:{"click":function($event){return _vm.selectPlayer(
                _vm.players[position.number]
                  ? _vm.players[position.number]._id
                  : undefined,
              )}}},[_c('b',[_vm._v(_vm._s(_vm.players[position.number] !== undefined ? _vm.players[position.number].shirtNum : ""))])]),_c('div',{staticClass:"player-name-wrap"},[_c('div',{staticClass:"player-name"},[_vm._v(" "+_vm._s(_vm.players[position.number] !== undefined ? `${_vm.players[position.number].firstName[0]}. ${ _vm.players[position.number].lastName } ${_vm.hadHIA(_vm.players[position.number])}` : "")+" ")])])])}),0)]),_c('div',{staticClass:"w-3 column"},[_c('div',{staticClass:"mini-header"},[_vm._v(" Interchange Bench ")]),_vm._l((_vm.playersInterchange),function(i){return _c('div',{key:i._id,staticClass:"mt-10 card name-tag d-flex clickable",class:{
          selected:
            _vm.selectedPlayers.length > 0 && _vm.selectedPlayers.includes(i._id),
          available:
            _vm.selectedPlayers.length > 0 &&
            Object.values(_vm.players).find((n) => n._id === _vm.selectedPlayers[0]),
        },on:{"click":function($event){return _vm.selectPlayer(i._id)}}},[_c('div',{staticClass:"number-circle"},[_c('b',[_vm._v(_vm._s(i.shirtNum))])]),_c('div',{staticClass:"flex-1 profile-name"},[_vm._v(" "+_vm._s(i.firstName)+" "),_c('b',[_vm._v(_vm._s(i.lastName))])]),(
            i.hia ||
              i.foulPlayHIA ||
              i.foulPlay ||
              i.hiaCleared ||
              i.hadHIA ||
              i.activatedReplacementPlayer
          )?_c('div',{staticClass:"red"},[_vm._v(" "+_vm._s(_vm.hiaStatus(i))+" ")]):_vm._e()])}),_c('br'),_c('div',{staticClass:"mini-header"},[_vm._v(" 18th Player Replacement ")]),_vm._l((_vm.replacementPlayer),function(i){return _c('div',{key:i._id,staticClass:"mt-10 card name-tag d-flex clickable",class:{
          selected:
            _vm.selectedPlayers.length > 0 && _vm.selectedPlayers.includes(i._id),
          available:
            _vm.selectedPlayers.length > 0 &&
            Object.values(_vm.players).find((n) => n._id === _vm.selectedPlayers[0]),
        },on:{"click":function($event){return _vm.selectPlayer(i._id)}}},[_c('div',{staticClass:"number-circle"},[_c('b',[_vm._v(_vm._s(i.shirtNum))])]),_c('div',{staticClass:"flex-1 profile-name"},[_vm._v(" "+_vm._s(i.firstName)+" "),_c('b',[_vm._v(_vm._s(i.lastName))])]),(
            i.hia || i.foulPlayHIA || i.foulPlay || i.hiaCleared || i.hadHIA
          )?_c('div',{staticClass:"red"},[_vm._v(" "+_vm._s(_vm.hiaStatus(i))+" ")]):_vm._e()])}),_c('br'),(!(_vm.confirmHomeStatus && _vm.confirmAwayStatus))?_c('div',[_c('div',{staticClass:"mini-header"},[_vm._v(" Team Staff ")]),_vm._l((_vm.staff),function(i){return _c('div',{key:i._id,staticClass:"mt-10 card name-tag d-flex"},[_c('div',{staticClass:"flex-1 profile-name"},[_vm._v(" "+_vm._s(i.firstName)+" "),_c('b',[_vm._v(_vm._s(i.lastName))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.getStaffRoleName(i.role)))])])])})],2):_vm._e(),_c('div',{staticClass:"mini-header"},[_vm._v(" Player Off Field ")]),(
          _vm.selectedPlayers.length > 0 &&
            _vm.playersInterchange.find((n) => n._id === _vm.selectedPlayers[0]) &&
            (_vm.playersInterchange.find((n) => n._id === _vm.selectedPlayers[0])
              .hia ||
              _vm.playersInterchange.find((n) => n._id === _vm.selectedPlayers[0])
                .foulPlayHIA ||
              _vm.playersInterchange.find((n) => n._id === _vm.selectedPlayers[0])
                .foulPlay ||
              _vm.playersInterchange.find(
                (n) => n._id === _vm.selectedPlayers[0].sinbin,
              ))
        )?_c('div',{staticClass:"mt-10 card name-tag d-flex highlight",on:{"click":function($event){_vm.showInterchangeModal = true}}},[_c('div',{staticClass:"flex-1 profile-name"},[_vm._v(" Move Here ")])]):_vm._e(),(
          _vm.selectedPlayers.length > 0 &&
            Object.values(_vm.players).find((n) => n._id === _vm.selectedPlayers[0])
        )?_c('div',{staticClass:"mt-10 card name-tag d-flex highlight",on:{"click":function($event){_vm.showInterchangeModal = true}}},[_c('div',{staticClass:"flex-1 profile-name"},[_vm._v(" Move Here ")])]):_vm._e(),_vm._l((_vm.playersOffField),function(i){return _c('div',{key:`offield_${i._id}`,staticClass:"mt-10 card name-tag d-flex clickable",class:{
          selected:
            _vm.selectedPlayers.length > 0 && _vm.selectedPlayers.includes(i._id),
          available:
            _vm.selectedPlayers.length > 0 &&
            Object.values(_vm.replacementPlayer).find(
              (n) => n._id === _vm.selectedPlayers[0],
            ),
        },on:{"click":function($event){return _vm.selectPlayer(i._id)}}},[_c('div',{staticClass:"number-circle"},[_c('b',[_vm._v(_vm._s(i.shirtNum))])]),_c('div',{staticClass:"flex-1 profile-name"},[_vm._v(" "+_vm._s(i.firstName)+" "),_c('b',[_vm._v(_vm._s(i.lastName))])]),_c('div',{staticClass:"red"},[_vm._v(" "+_vm._s(_vm.offFieldStatus(i))+" ")])])})],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }