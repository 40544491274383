<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container short">
        <div class="modal-header">
          <div class="head">
            <div class="start">
              <div class="breadcrumb">
                <p>Interchange Override</p>
                <span class="slash">/</span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-body">
          <div class="align-text-center">
            <div class="d-flex players">
              <div v-if="selectedPlayers[0]" class="card name-tag d-flex mt-10">
                <div class="number-circle">
                  <b>{{ selectedPlayers[0].shirtNum }}</b>
                </div>
                <div class="profile-name flex-1">
                  {{ selectedPlayers[0].firstName }}
                  <b>{{ selectedPlayers[0].lastName }}</b>
                  <br />
                  <span>{{ selectedPlayers[0].position.name }}</span>
                </div>
              </div>
              <div v-if="selectedPlayers[1]" class="card name-tag d-flex mt-10">
                <div class="number-circle">
                  <b>{{ selectedPlayers[1].shirtNum }}</b>
                </div>
                <div class="profile-name flex-1">
                  {{ selectedPlayers[1].firstName }}
                  <b>{{ selectedPlayers[1].lastName }}</b>
                  <br />
                  <span>{{ selectedPlayers[1].position.name }}</span>
                </div>
              </div>
            </div>
            <div class="d-flex-column loose-column-button-wrapper">
              <button
                class="transparent"
                v-for="i in interchanges"
                :key="i.type"
                @click="selected = i"
                :class="{ selected: selected && selected.type === i.type }"
              >
                {{ i.name }}
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div class="modal-footer">
          <hr />
          <div class="buttons">
            <button class="loose" @click="submit" :disabled="disableButton">
              ok
            </button>
            <button class="loose transparent" @click="$emit('close')">
              cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'OverrideModal',
  props: ['selectedPlayers'],
  data() {
    return {
      selected: undefined,
      interchanges: [
        { name: 'Sin Bin', type: 14 },
        { name: 'Send Off', type: 16 },
      ],
    };
  },
  computed: {
    disableButton() {
      return (
        this.mins === '' ||
        this.secs === '' ||
        this.selected === undefined ||
        parseInt(this.mins) > 40 ||
        parseInt(this.secs) > 59 ||
        parseInt(this.mins) < 0 ||
        parseInt(this.secs) < 0
      );
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  max-width: 600px;
}
.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}
.big-input {
  width: 100px;
  height: 40px;
  font-size: 1.5em;
  border: 2px solid black;
  margin-left: 10px;
  text-align: center;
  font-weight: bold;
  color: black;
}
.cap {
  font-size: 1.2em;
  color: grey;
}
button {
  width: 130px;
}

.line {
  display: flex;
  justify-content: space-between;
}
.name {
  font-size: 1.5em;
  display: flex;
}
.head {
  height: 50px;
  display: flex;
  margin-bottom: 10px;

  > * {
    margin-top: auto;
    margin-bottom: auto;
  }

  .start {
    flex-grow: 1;
    text-align: left;
  }
}

.loose-column-button-wrapper {
  > button,
  div {
    margin-top: 15px;
    word-wrap: break-word; /* All browsers since IE 5.5+ */
    overflow-wrap: break-word; /* Renamed property in CSS3 draft spec */
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.66rem;
  }
}
.align-text-center {
  margin-left: auto;
  margin-right: auto;
}

.squares {
  margin: auto;
}
.square {
  display: flex;
  width: 70px;
  height: 70px;
  background: #ededed;
  input[type='number'] {
    appearance: none;
    -moz-appearance: textfield;
    width: 100%;
    font-size: 1.4em;
    font-weight: bold;
    margin: auto;
    text-align: center;
    color: $secondary;
    border: none;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}
.small-text-wrap {
  width: 100%;
  text-align: left;
  .small-text {
    text-transform: uppercase;
    font-size: 0.7em;
  }
}
.stuff {
  width: 30px;
}
.name-tag {
  text-align: left;
  width: 30%;

  .profile-name {
    margin: auto;

    span {
      text-transform: uppercase;
      color: $primary;
      font-size: 0.75rem;
    }
  }
}
.players {
  width: 100%;
  margin-bottom: 20px;
  justify-content: space-between;
}
.number-circle {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
